import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { authValidateUser } from '@redux/actions/auth.action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isValidUser } from '@react/utils/auth.util';
import BlankPage from '@react/components/blank/blank.component';
import { authValidSelector } from '@redux/selectors/auth.selector';
import { isLoadingUserSelector, oidcUserSelector } from '@redux/selectors/oidc.selector';
import { isLoggingOutSelector } from '@redux/selectors/account.selector';

interface AnonymouseRouteState {
  isValid: boolean;
  oidcUser: any;
  isLoadingUser: boolean;
  isLoggingOut: boolean;
}

interface AnonymouseRouteDispatch {
  validate: (boolean) => void;
}

interface AnonymouseRouteOwnProps {
  path: string;
  exact?: boolean;
  component?;
  render?;
}

type AnonymouseRouteProps = 
  AnonymouseRouteState &
  AnonymouseRouteDispatch &
  AnonymouseRouteOwnProps;
 
const AnonymouseRoute: React.FC<AnonymouseRouteProps> = (props) => {
  const { isValid, validate, oidcUser, isLoadingUser, isLoggingOut } = props;
  useEffect(() => {
    if (!isLoggingOut && !isLoadingUser && navigator.onLine) {
      const validUser = isValidUser(oidcUser);
      validate(validUser);
    }
  }, [oidcUser, isLoadingUser]);
  if (isValid === undefined) {
    return <BlankPage />;
  }
  return (
    <Route
      {...props}
    />
  );
};
 
const mapStateToProps = (state) => ({
  isValid: authValidSelector(state),
  oidcUser: oidcUserSelector(state),
  isLoadingUser: isLoadingUserSelector(state),
  isLoggingOut: isLoggingOutSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      validate: authValidateUser,
    },
    dispatch,
  ),
});

const connected = connect<
AnonymouseRouteState,
AnonymouseRouteDispatch,
AnonymouseRouteOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AnonymouseRoute);

export default connected;

