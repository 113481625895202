import { defineMessages } from 'react-intl';

export const scope = 'src.react.components.drawer';

export default defineMessages({
  billion: {
    id: `${scope}.billion`,
    defaultMessage: 'Billion',
  },
  businessLines: {
    id: `${scope}.businessLines`,
    defaultMessage: 'Business Lines',
  },
  companies: {
    id: `${scope}.companies`,
    defaultMessage: 'Companies',
  },
  cookiesPolicy: {
    id: `${scope}.cookiesPolicy`,
    defaultMessage: 'Cookies Policy',
  },
  countriesRegions: {
    id: `${scope}.countriesRegions`,
    defaultMessage: 'Countries & regions',
  },
  findBusinessLines: {
    id: `${scope}.findBusinessLines`,
    defaultMessage: 'FIND BUSINESS LINES',
  },
  findCompanies: {
    id: `${scope}.findCompanies`,
    defaultMessage: 'FIND COMPANIES',
  },
  finanicals: {
    id: `${scope}.finanicals`,
    defaultMessage: 'Financials',
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'From',
  },
  marketCapitalization: {
    id: `${scope}.marketCapitalization`,
    defaultMessage: 'Market Cap',
  },
  maximum: {
    id: `${scope}.maximum`,
    defaultMessage: 'Max',
  },
  mentions: {
    id: `${scope}.mentions`,
    defaultMessage: 'Mentions',
  },
  million: {
    id: `${scope}.million`,
    defaultMessage: 'Million',
  },
  minimum: {
    d: `${scope}.minimum`,
    defaultMessage: 'Min',
  },
  news: {
    id: `${scope}.news`,
    defaultMessage: 'News',
  },
  noBusinessLinesSelected: {
    id: `${scope}.noBusinessLinesSelected`,
    defaultMessage: 'NO BUSINESS LINES SELECTED',
  },
  noCompaniesSelected: {
    id: `${scope}.noCompaniesSelected`,
    defaultMessage: 'NO COMPANIES SELECTED',
  },
  none: {
    id: `${scope}.none`,
    defaultMessage: 'None',
  },
  numberEmployees: {
    id: `${scope}.numberEmployees`,
    defaultMessage: 'No. Employees',
  }, 
  relationshipSearch: {
    id: `${scope}.relationshipSearch`,
    defaultMessage: 'Relationship Search',
  },
  keyword: {
    id: `${scope}.keyword`,
    defaultMessage: 'Search keyword in',
  },
  ownership: {
    id: `${scope}.ownership`,
    defaultMessage: 'Ownership',
  },
  plBeforeTax: {
    id: `${scope}.plBeforeTax`,
    defaultMessage: 'Profit Before Tax',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  publicationDate: {
    id: `${scope}.publicationDate`,
    defaultMessage: 'Publication Date',
  },
  operatingRevenue: {
    id: `${scope}.operatingRevenue`,
    defaultMessage: 'Revenue',
  },
  scoutAi: {
    id: `${scope}.scoutAi`,
    defaultMessage: 'Scout AI',
  },
  scoutAiTopics: {
    id: `${scope}.scoutAiTopics`,
    defaultMessage: 'Scout AI Topics',
  },
  searchBusinessLines: {
    id: `${scope}.searchBusinessLines`,
    defaultMessage: 'Search Business Lines',
  },
  searchCompanies: {
    id: `${scope}.searchCompanies`,
    defaultMessage: 'Search Companies',
  },
  searchNews: {
    id: `${scope}.searchNews`,
    defaultMessage: 'Search News',
  },
  sectors: {
    id: `${scope}.sectors`,
    defaultMessage: 'Sectors',
  },
  shareholdersFunds: {
    id: `${scope}.shareholdersFunds`,
    defaultMessage: "Shareholders' Funds",
  },
  source: {
    id: `${scope}.source`,
    defaultMessage: 'Source',
  },
  termCondition: {
    id: `${scope}.termCondition`,
    defaultMessage: 'Terms & Conditions',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'To',
  },
  totalAssets: {
    id: `${scope}.totalAssets`,
    defaultMessage: 'Total Assets',
  },
  trillion: {
    id: `${scope}.trillion`,
    defaultMessage: 'Trillion',
  },
  businessLinesFound: {
    id: `${scope}.businessLinesFound`,
    defaultMessage: '{count} Business Lines Found',
  },
  businessLineFound: {
    id: `${scope}.businessLineFound`,
    defaultMessage: '{count} Business Line Found',
  },
  faq: {
    id: `${scope}.faq`,
    defaultMessage: 'FAQ',
  },
  informationTransmission: {
    id: `${scope}.informationTransmission`,
    defaultMessage: 'Information Transmission',
  },
  copyright: {
    id: `${scope}.copyright`,
    defaultMessage: 'Copyright { year } © All rights reserved.',
  },
});
