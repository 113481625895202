import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '@react/components/props/common/svgicon.props';

export interface IInfoIconProps extends ISvgIconProps {}

const InfoIcon = (props: IInfoIconProps) => {
  const { color, className } = props;
  const svg = (
    <g id="icon-info-outline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <rect id="base" x="0" y="0" width="24" height="24" />
        <path d="M3.66666667,3.66666667 L3.66666667,20.3333333 L20.3333333,20.3333333 L20.3333333,3.66666667 L3.66666667,3.66666667 Z M22,2 L22,22 L2,22 L2,2 L22,2 Z M12,8.66666667 C12.4602373,8.66666667 12.8333333,8.29357062 12.8333333,7.83333333 C12.8333333,7.37309604 12.4602373,7 12,7 C11.5397627,7 11.1666667,7.37309604 11.1666667,7.83333333 C11.1666667,8.29357062 11.5397627,8.66666667 12,8.66666667 Z M9.5,17 L9.5,15.3333333 L14.5,15.3333333 L14.5,17 L9.5,17 Z M11.1666667,12 L9.5,12 L9.5,10.3333333 L12.8333333,10.3333333 L12.8333333,17 L11.1666667,17 L11.1666667,12 Z" id="info" fill={color} fillRule="nonzero" />
      </g>
    </g>
  );

  return (
    <SvgIcon viewBox="0 0 24 24" className={className}>
      {svg}
    </SvgIcon>
  );
};

InfoIcon.defaultProps = {
  color: '#404040',
} as Partial<IInfoIconProps>;

export default InfoIcon;