import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Fade, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ZERO, HUNDRED } from '@constants/common';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 2001,
    left: 0,
    backgroundColor: 'transparent',
  },
  noDisplay: {
    display: 'none',
  },
});

interface IMainProgressBarProps {
  currentProgress
}

const MainProgressBar = (props: IMainProgressBarProps) => {
  const { currentProgress } = props;
  const classes = useStyles();

  const [prevProgress, setPrevProgress] = useState(ZERO);
  const [maxProgress, setMaxProgress] = useState(ZERO);
  const [finished, setFinished] = useState(ZERO);
  const [currentBarProgress, setCurrentBarProgress] = useState(ZERO);
  const [hidden, setHidden] = useState(false);
  const [isHidden] = useDebounce(hidden, 500);

  const updateProgress = useDebouncedCallback(() => {
    if (currentProgress > prevProgress) {
      const difference = currentProgress - prevProgress;
      const updatedMax = maxProgress + difference;
      const barProgressPercentage = Math.min(finished / updatedMax * HUNDRED, HUNDRED);
      setCurrentBarProgress(barProgressPercentage);
      setMaxProgress(updatedMax);
      if (barProgressPercentage === HUNDRED) {
        setHidden(true);
      }
    } else if (currentProgress < prevProgress) {
      const difference = prevProgress - currentProgress;
      const updatedFinished = finished + difference;
      const barProgressPercentage = Math.min(updatedFinished / maxProgress * HUNDRED, HUNDRED);
      setCurrentBarProgress(barProgressPercentage);
      setFinished(updatedFinished);
      if (barProgressPercentage === HUNDRED) {
        setHidden(true);
      }
    }
    setPrevProgress(currentProgress);
  }, 300, { leading: true });
    
  useEffect(() => {
    updateProgress.callback();
  }, [currentProgress]);
  return (
    <Fade in={!isHidden}>
      <LinearProgress
        className={classnames(classes.root)}
        variant="determinate"
        value={currentBarProgress}
      />
    </Fade>
  );
};

const mapStateToProps = (state: any) => ({
  currentProgress: state.getIn(['Progress', 'currentProgress']),
});

export default connect(mapStateToProps)(MainProgressBar);