import React, { ReactNode } from 'react';
import { Typography, IconButton } from '@mui/material';
import { AnnouncementType } from '@models/announcementType';
import useStyles from './styles';

export interface IAnnouncementBaseProps {
  leadingIcon?: ReactNode,
  cancelIcon?: ReactNode,
  onCancel?: () => void,
  message: ReactNode,
  type?: AnnouncementType,
  className?: string,
}

function AnnouncementBase(props: IAnnouncementBaseProps) {
  const {
    leadingIcon,
    cancelIcon,
    onCancel,
    message,
    type,
    className,
  } = props;

  const classes = useStyles();
  const backgroundClassName = type === AnnouncementType.warning ? classes.backgroundWarning : classes.backgroundInfo;

  return (
    <div className={`${classes.root} ${backgroundClassName} ${className}`}>
      <div>
        {leadingIcon}
        <Typography
          variant="body1"
          component="span"
          className={classes.textMessage}
        >
          {message}
        </Typography>
        <IconButton
          className={classes.buttonCancelRoot}
          disableRipple
          onClick={onCancel}
          size="large"
        >
          {cancelIcon}
        </IconButton>
      </div>
    </div>
  );

}

export default AnnouncementBase;