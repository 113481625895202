import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { authValidateUser } from '@redux/actions/auth.action';
import { oidcLogin as oidcLoginAction } from '@redux/actions/account.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isValidUser } from '@react/utils/auth.util';
import BlankPage from '@react/components/blank/blank.component';
import { authValidSelector } from '@redux/selectors/auth.selector';
import { isLoadingUserSelector, oidcUserSelector } from '@redux/selectors/oidc.selector';
import { getAccountInfoSelector, isLoggingOutSelector } from '@redux/selectors/account.selector';
import { goLogin } from './route.common';
import { useCustomOidc } from '../oidc/customOidcProvider.component';
import { isEmpty } from 'lodash';

interface AuthRouteState {
  isValid: boolean;
  oidcUser: any;
  isLoadingUser: boolean;
  isLoggingOut: boolean;
  accountInfo: any;
}

interface AuthRouteDispatch {
  validate: (boolean) => void;
  oidcLogin;
}

interface AuthRouteOwnProps {
  path: string;
  exact?: boolean;
  component?;
  render?;
}

type AuthRouteProps = 
  AuthRouteState &
  AuthRouteDispatch &
  AuthRouteOwnProps;
 
const AuthRoute: React.FC<AuthRouteProps> = (props) => {
  const { isValid, validate, oidcLogin, oidcUser, isLoadingUser, isLoggingOut, accountInfo } = props;
  const location = useLocation();
  const oidc = useCustomOidc();

  useEffect(() => {
    if (!isLoggingOut && !isLoadingUser && navigator.onLine) {
      const validUser = isValidUser(oidcUser);
      validate(validUser);
      if (!validUser) {
        goLogin(location, () => {
          oidcLogin(oidc.userManager);
        });
      }
    }
  }, [oidcUser, isLoadingUser]);
  if (!isValid || isEmpty(accountInfo)) {
    return <BlankPage />;
  }
  return (
    <Route
      {...props}
    />
  );
};
 
const mapStateToProps = (state) => ({
  isValid: authValidSelector(state),
  oidcUser: oidcUserSelector(state),
  isLoadingUser: isLoadingUserSelector(state),
  isLoggingOut: isLoggingOutSelector(state),
  accountInfo: getAccountInfoSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      validate: authValidateUser,
      oidcLogin: oidcLoginAction,
    },
    dispatch,
  ),
});

const connected = connect<
AuthRouteState,
AuthRouteDispatch,
AuthRouteOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AuthRoute);

export default connected;

