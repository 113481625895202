import React from 'react';
import IAnnouncement from '@models/announcement';
import { Typography } from '@mui/material';
import { ANNOUNCEMENT } from '@constants/common';
import { ANNOUNCEMENT as ANNOUNCEMENT_STYLE } from '@constants/style';
import { AnnouncementType } from '@models/announcementType';
import useStyles from './styles';
import AnnouncementBase from './announcementBase.component';
import InfoIcon from '../icons/infoIcon';
import CloseIcon from '../icons/closeIcon.component';

export interface IAnnouncementInfoProps {
  onCancel?: (announcement: IAnnouncement) => void,
  announcement: IAnnouncement,
  className?: string,
}

function AnnouncementInfo(props: IAnnouncementInfoProps) {
  const {
    onCancel,
    announcement,
    className,
  } = props;

  const classes = useStyles();

  const handleOnCancel = () => {
    onCancel && onCancel(announcement);
  };

  const message = (
    <span className={classes.textMessageInfo}>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html:
            announcement?.announcementBody,
        }}
      />
    </span>
  );

  const color = ANNOUNCEMENT.TYPE.MAP[announcement.type] ===
    AnnouncementType.warning ? ANNOUNCEMENT_STYLE.COLOR.WARNING : ANNOUNCEMENT_STYLE.COLOR.INFO;

  return (
    <AnnouncementBase
      className={className}
      leadingIcon={<InfoIcon color={color} />}
      cancelIcon={<CloseIcon color={color} />}
      message={message}
      onCancel={handleOnCancel}
      type={ANNOUNCEMENT.TYPE.MAP[announcement.type]}
    />
  );
}

export default AnnouncementInfo;