import React from 'react';
import { SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ISvgIconProps } from '../props/common/svgicon.props';

export interface ISNikkeiIconProps extends ISvgIconProps {
  reverseColor?: boolean
  viewBox?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '98px',
      height: '35px',
    },
  }),
);

const NikkeiIcon = (props: ISNikkeiIconProps) => {
  const { className, reverseColor, viewBox } = props;
  const classes = useStyles();
  const svg = (
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Logos-/-Nikkei-&amp;-FT-(for-dark-bg)" transform="translate(-1.000000, -1.000000)">
        <g id="Logo-Nikkei-and-FT-footer" transform="translate(1.000000, 1.000000)">
          <g id="logo-nikkei" transform="translate(0.000000, 12.573529)" fill={reverseColor ? 'black' : 'white'} fillRule="nonzero">
            <polygon id="Shape" points="12.7706067 0.102687627 10.4648027 8.4546146 7.59141619 0.102687627 3.68928637 0.102687627 0.106421722 12.9728702 3.37002121 12.9728702 3.37002121 12.9728702 3.37002121 12.9728702 5.85319473 4.03904665 8.90395077 12.9728702 12.4513415 12.9728702 12.4513415 12.9728702 16.0342062 0.102687627" />
            <polygon id="Shape" points="59.2414254 0.102687627 55.6585608 12.9728702 58.9221602 12.9728702 62.5050249 0.102687627" />
            <polygon id="Shape" points="17.9852711 0.102687627 14.4024064 12.9728702 17.6660059 12.9728702 21.2488706 0.102687627" />
            <polygon id="Shape" points="54.416974 7.70157201 55.0909782 5.30552738 49.5215748 5.30552738 50.2665268 2.73833671 56.4744606 2.73833671 57.1839388 0.102687627 47.7124055 0.102687627 44.2359626 12.6990365 41.6463673 6.05856998 40.6530979 6.05856998 40.6530979 6.05856998 46.75461 0.102687627 42.8879541 0.102687627 37.2121289 5.57936105 38.7375069 0.102687627 35.4739074 0.102687627 31.9619906 12.7674949 29.3723954 6.05856998 28.343652 6.05856998 28.343652 6.05856998 34.4451641 0.102687627 30.6139821 0.102687627 24.9381569 5.57936105 26.428061 0.102687627 23.1644616 0.102687627 19.6170708 12.9728702 19.6880186 12.9728702 22.8451964 12.9728702 22.8806703 12.9728702 24.7962613 6.05856998 25.9314263 6.05856998 28.2727042 12.9728702 35.1191684 12.9728702 35.1546423 12.9728702 37.0702333 6.05856998 38.2408722 6.05856998 40.5821501 12.9728702 53.7074959 12.9728702 54.416974 10.3714503 48.1380924 10.3714503 48.8830444 7.70157201" />
          </g>
        </g>
      </g>
    </g>
  );

  return (
    <SvgIcon viewBox={viewBox} className={`${classes.root} ${className}`}>
      {svg}
    </SvgIcon>
  );
};

NikkeiIcon.defaultProps = {
  reverseColor: false,
} as Partial<ISNikkeiIconProps>;

export default NikkeiIcon;