import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Box } from '@mui/material';
import HsAppBar from '@react/components/appbar/appbar.component';
import { isEmpty } from 'lodash';
import { getLocalStorageAnnouncement, saveLocalStorageAnnouncement, hasAppBar } from '@react/utils/common.util';
import { IRouteProperties } from '@interface/route';
import UiControls from '@models/uiControl';
import MainProgressBar from '@react/components/progress/main/mainProgress.component';
import { getAccountInfoSelector, loggedInSelector, subcriptionFeatureSelector } from '@redux/selectors/account.selector';
import { authValidSelector } from '@redux/selectors/auth.selector';
import { isLoadingUserSelector } from '@redux/selectors/oidc.selector';
import IAnnouncement from '@models/announcement';
import { updateDrawerAlertControl, updateDrawerSaveFieldShow } from '@redux/actions/configurations.actions';
import BookmarkSnackBar from  '@react/components/bookmark/bookmarkSnackBar.component';
import { routesUiProps, IRouteUiProps } from './standard.layout.routes';
import StandardLayoutContent from './standard.layout.content';

interface IRouteWithUiProps extends IRouteProperties {
  uiProps: IRouteUiProps;
}

interface IStandardLayoutOwnProps {
  routes?: IRouteProperties[];
  uiRoutes?: IRouteWithUiProps[];
}

interface IStandardLayoutStateProps {
  isLogin: boolean;
  isAuthValid?: boolean;
  isLoadingUser?: boolean;
  subcriptionFeaturePermissionModel?: any;
  drawerAlertControl?: DRAWER_ALERT_CONTROL;
  drawerSaveFieldShow?: boolean;
  accountInfo: any;
}

interface IStandardLayoutDispatchProps {
  updateDrawerAlertControl,
  updateDrawerSaveFieldShow
}

type StandardLayoutContainerProps =
  IStandardLayoutOwnProps &
  IStandardLayoutStateProps &
  IStandardLayoutDispatchProps;

enum DRAWER_ALERT_CONTROL {
  none,
  targetList,
  savedSearch,
}

const StandardLayoutContainer: React.FC<StandardLayoutContainerProps>  = (props) => {
  const {
    isLogin,
    isAuthValid,
    isLoadingUser,
    subcriptionFeaturePermissionModel,
    updateDrawerAlertControl,
    updateDrawerSaveFieldShow,
    drawerAlertControl,
    drawerSaveFieldShow,
    uiRoutes,
    accountInfo,
  } = props;

  /**
   * In component level, specify `textBackToTop` in props or state
   */
  const storedAnnouncement = getLocalStorageAnnouncement();

  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(!isEmpty(storedAnnouncement?.announcement) && !storedAnnouncement.isClosed);
  const showAppBar = hasAppBar();

  const handleOnAnnouncementCancel = (announcement: IAnnouncement) => {
    saveLocalStorageAnnouncement(announcement, true);
    setShowAnnouncement(false);
  };
  // *** CAUTION ***
  // The functional call that trigger rerender of routes component,
  // don't put them here, or the performance might be incluenced.
  const uiControlProps: UiControls = React.useMemo(() => ({
    showDrawerAlertTargets: () => {
      updateDrawerAlertControl(DRAWER_ALERT_CONTROL.targetList);
    },
    setDrawerAlertSaveSearch: () => {
      updateDrawerAlertControl(DRAWER_ALERT_CONTROL.savedSearch);
      updateDrawerSaveFieldShow(true);
    },
    targetListDrawerShowing: drawerAlertControl === DRAWER_ALERT_CONTROL.targetList,
    savedSearchDrawerShowing: drawerAlertControl === DRAWER_ALERT_CONTROL.savedSearch,
  }), [drawerAlertControl]);
  
  const renderAppbar = () => {
    if (isLoadingUser || (!isAuthValid && !showAppBar) || isEmpty(accountInfo)) {
      return null;
    }
    return (
      <HsAppBar>
        <MainProgressBar />
        <HsAppBar.Toolbar />
      </HsAppBar>
    );
  };

  const renderResponsiveContainer = () => {
    return (
      <StandardLayoutContent
        uiRoutes={uiRoutes}
        uiControlProps={uiControlProps}
        showAnnouncement={showAnnouncement}
        storedAnnouncement={storedAnnouncement}
        handleOnAnnouncementCancel={handleOnAnnouncementCancel}
        subcriptionFeaturePermissionModel={subcriptionFeaturePermissionModel}
      />
    );
  };

  return (
    <Box className="app">
      {renderAppbar()}
      {renderResponsiveContainer()}
      <BookmarkSnackBar />
    </Box>
  );
};

const getRoutes = (props) => props.routes;

const routesSelector = createSelector([getRoutes], (routes) => {
  return routes.map((route) => {
    return {
      ...route,
      uiProps: route.path in routesUiProps ? routesUiProps[route.path] : {},
    };
  });
});

const mapStateToProps = (state: any, props: any) => ({
  isLogin: loggedInSelector(state),
  isAuthValid: authValidSelector(state),
  isLoadingUser: isLoadingUserSelector(state),
  uiRoutes: routesSelector(props),
  subcriptionFeaturePermissionModel: subcriptionFeatureSelector(state),
  drawerAlertControl: state.getIn(['Configurations', 'drawerAlertControl']),
  drawerSaveFieldShow: state.getIn(['Configurations', 'drawerSaveFieldShow']),
  accountInfo: getAccountInfoSelector(state),
}); 

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(
    {
      updateDrawerAlertControl,
      updateDrawerSaveFieldShow,
    },
    dispatch,
  ),
});

export default connect<
IStandardLayoutStateProps,
IStandardLayoutDispatchProps,
IStandardLayoutOwnProps
>(mapStateToProps, mapDispatchToProps)(StandardLayoutContainer);
