import React from 'react';
import { STANDARD_LAYOUT_DEFAULT_UI_PROPS } from '@constants/component';
import { Box, Container, useMediaQuery } from '@mui/material';
import classnames from 'classnames';
import { FEATURE_CODE } from '@constants/common';
import Error401Page from '@react/pages/error/error401.page';
import { useStyles } from './styles';
import { hideMenu } from '@react/utils/common.util';
import theme from '@react/theme/theme';

export interface ResponsiveContentProps {
  route;
  routeProps;
  uiControlProps;
  showAnnouncement;
  subcriptionFeaturePermissionModel
}
 
const ResponsiveContent: React.FC<ResponsiveContentProps> = ({
  route,
  routeProps,
  uiControlProps,
  showAnnouncement,
  subcriptionFeaturePermissionModel,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const Component = route.component;
  const noOverflow = route.uiProps.noOverflow !== undefined ? route.uiProps.noOverflow : STANDARD_LAYOUT_DEFAULT_UI_PROPS.noOverflow;
  const fullBodyWidth = route.uiProps.fullBodyWidth !== undefined ? route.uiProps.fullBodyWidth : STANDARD_LAYOUT_DEFAULT_UI_PROPS.fullBodyWidth;
  
  const availableForStartUser = route.availableForStartUser;
  const isStarterUser = subcriptionFeaturePermissionModel.includes(FEATURE_CODE.HIDE_ARTICLE_TOOLBAR_RIGHTTABS);
  const availableForNonActivatedUser = route.availableForInactvieUser;
  const isActiveUser = true;
  const announcementIsFixed = route.uiProps.announcementIsFixed !== undefined ? route.uiProps.announcementIsFixed : STANDARD_LAYOUT_DEFAULT_UI_PROPS.announcementIsFixed;

  const classes = useStyles({
    hasAnnouncement: showAnnouncement,
    fixedAnnouncement: announcementIsFixed,
    hideMenu: hideMenu(),
    isMobile
  });

  if (fullBodyWidth) {
    return (
      <Box
        className={classnames(
          classes.contentRootBase,
          classes.contentFullWidthRoot, {
            [classes.contentRootFixed]: noOverflow,
          })}
      >
        <Box
          className={classnames(classes.contentBodyFullWidth, {
            [classes.contentBodyFixed]: noOverflow,
          })}
        >
          {
            (!availableForStartUser && isStarterUser) || (!availableForNonActivatedUser && !isActiveUser) ? 
              <Error401Page />
              :
              <Component
                {...routeProps}
                {...uiControlProps}
              />
          }
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.contentRootBase}>
      <Container
        disableGutters
        className={classnames(classes.contentRoot, {
          [classes.contentRootFixed]: noOverflow,
        })}
      >
        <Container
          className={classnames(classes.contentBody, {
            [classes.contentBodyFixed]: noOverflow,
          })}
        >
          {
            (!availableForStartUser && isStarterUser) || (!availableForNonActivatedUser && !isActiveUser) ? 
              <Error401Page />
              :
              <Component
                {...routeProps}
                {...uiControlProps}
              />              
          }
        </Container>
      </Container>
    </Box>
  );
};
 
export default ResponsiveContent;