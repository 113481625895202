import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';
import {
  SEARCH_DRAWER_WIDTH,
  FONT,
  SEARCH_HEADER_HEIGHT,
  SCOUT_ASIA_FOOTER_HEIGHT,
  APPBAR,
  SEARCH_HEADER_HEIGHT_MOBILE,
  SEARCH_DRAWER_TOP_MOBILE,
  SEARCH_DRAWER_TOP,
} from '@constants/style';

export const useStyles = makeStyles((theme) => createStyles({
  sideBarWrapper: {
    width: `${SEARCH_DRAWER_WIDTH}px`,
    display: 'flex',
    flexFlow: 'column',
  },
  drawerPaper: {
    width: `${SEARCH_DRAWER_WIDTH}px`,
    backgroundColor: FONT.COLOR.DRAWER.BACKGROUND,
    overflowY: 'visible',
    [`${'visibility'}`]: 'visible !important',
    position: 'absolute',
  },
  drawPaperUnshadow: {
    boxShadow: 'none',
    borderRight: 'none',
  },
  drawerContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    width: '100%',
    backgroundColor: FONT.COLOR.DRAWER.BACKGROUND,
  },
  drawerFooterRoot: {
    width: '100%',
    padding: '12px 0',
    backgroundColor: FONT.COLOR.DRAWER.FOOTER,
    color: '#fff',
  },
  nikkeIconLink: {
    height: '37px',
    textDecoration: 'none'
  },
  drawerFooterLinkRoot: {
    '& > span': {
      '& a': {
        color: 'white',
        textTransform: 'none',
        fontWeight: FONT.WEIGHT.MEDIUM,
      },
      '& a:hover': {
        textDecoration: 'underline',
      },
      '& a:not(:first-child)': {
        marginLeft: theme.spacing(1.5),
      },
    },
  },
  filterRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  filterContentRoot: {
    flex: '1 0 auto;',
  },
  filterActionButtonRoot: {
    flex: '0 1 auto;',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '& button:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
    bottom: '0',
    position: 'absolute',
    width: '100%',
    '@media (max-height: 738px)': {
      position: 'static',
    },
  },
  filterNewsMentionHeaderRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '22px 16px 5px',
    borderBottom: `2px solid ${FONT.COLOR.DRAWER.BORDER1}`,
    '& > svg': {
      fontSize: '12px',
    },
    '& > span': {
      color: FONT.COLOR.TAB.CONTRAST_TEXT,
      fontWeight: FONT.WEIGHT.SEMIBOLD,
      marginLeft: theme.spacing(1),
    },
    backgroundColor: FONT.COLOR.DRAWER.BACKGROUND,
    zIndex: 1,
  },
  appbar: {
    backgroundColor: FONT.COLOR.DRAWER.BACKGROUND,
    width: `${SEARCH_DRAWER_WIDTH}px`,
    height: 'auto',
  },
  tabs: {
    marginTop: '11px',
    borderBottom: `1px solid ${FONT.COLOR.DRAWER.BORDER1}`,
    padding: '0 16px',
  },
  tabsFlexContainer: {},
  tab: {
    minWidth: '50px',
  },
  tabTextColorPrimary: {
    color: alpha(theme.palette.primary.contrastText, 0.85),
  },
  tabSelected: {
    '& span:first-child': {
      backgroundColor: theme.palette.tab.main,
      color: theme.palette.tab.contrastText,
    },
  },
  tabIndicator: {
    backgroundColor: theme.palette.tab.contrastText,
  },
  tabPanel: {
    height: '100%',
    '& .MuiBox-root': {
      height: 'inherit',
    },
  },
  searchbarContainer: {
    zIndex: 1,
  },
  menuScoutAiContainerRoot: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      background: '#eef4f6',
      '&:horizontal': {
        height: '13px',
      },
      '&:vertical': {
        width: '13px',
      },
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid #eef4f6',
      backgroundColor: '#bababa',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: '#eef4f6',
    },
  },
  menuCompanyContainerRoot: {
    padding: '16px 0px 32px 0px !important',
  },
  menuCountryContentRoot: {
    width: '756px!important',
    minHeight: '306px',
  },
  menuFinancialContainerRoot: {
    padding: '16px 24px 16px 32px !important',
  },
  menuScoutAiContentRoot: {
    width: '758px',
  },
  menuItemSourcePrimaryHovered: {
    '&:after': {
      borderColor: 'transparent transparent transparent #fff',
    },
  },
  menuItemSourcePrimarySelected: {
    '&:after': {
      borderColor: 'transparent transparent transparent #fff',
    },
  },
  menuItemSectorPrimary: {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  menuItemSectorPrimaryHovered: {
    '&:after': {
      paddingLeft: '4px',
      borderColor: 'transparent transparent transparent #fff',
    },
  },
  menuItemSectorPrimarySelected: {
    '&:after': {
      paddingLeft: '4px',
      borderColor: 'transparent transparent transparent #fff',
    },
  },
  menuItemSectorSecondarySelected: {
    '& p': {
      color: theme.palette.primary.main,
    },
  },
  countryMenuItem: {
    '& p': {
      fontWeight: FONT.WEIGHT.REGULAR,
    },
  },
  nikkeiIcon: {
    width: '103px',
    height: '37px',
  },
  drawerMenu: {
    borderBottom: `1px solid ${FONT.COLOR.DRAWER.BORDER2} !important`,
  },
  drawerMenuContainer: {
    zIndex: 0,
    backgroundColor: FONT.COLOR.DRAWER.BACKGROUND,
  },
  companyFilterMenuAnimationDummyMask: {
    height: `calc(100% - ${SEARCH_HEADER_HEIGHT}px - ${APPBAR.HEIGHT}px - ${SCOUT_ASIA_FOOTER_HEIGHT}px - 5px)`,
    backgroundColor: FONT.COLOR.DRAWER.BACKGROUND,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      height: `calc(100% - ${SEARCH_HEADER_HEIGHT_MOBILE}px - ${APPBAR.HEIGHT}px - ${SCOUT_ASIA_FOOTER_HEIGHT}px - 5px)`,
    },
  },
  newsFilterMenuAnimationDummyMask: {
    height: `calc(100% - ${SEARCH_HEADER_HEIGHT}px - ${APPBAR.HEIGHT}px - ${SCOUT_ASIA_FOOTER_HEIGHT}px - 86px)`,
    backgroundColor: FONT.COLOR.DRAWER.BACKGROUND,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      height: `calc(100% - ${SEARCH_HEADER_HEIGHT_MOBILE}px - ${APPBAR.HEIGHT}px - ${SCOUT_ASIA_FOOTER_HEIGHT}px - 86px)`,
    },
  },
  companyIcon: {
    width: '20px',
    height: 'auto',
  },
  searchbarInput: {
    '& input::placeholder': {
      color: '#505050 !important',
      opacity: 1,
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
    '& input:-moz-placeholder': {
      color: '#505050 !important',
      opacity: 1,
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
    '& input::-webkit-input-placeholder': {
      color: '#505050 !important',
      opacity: 1,
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
    '& input::-ms-input-placeholder': {
      color: '#505050 !important',
      opacity: 1,
      fontWeight: FONT.WEIGHT.MEDIUM,
    },
  },
  searchbarRoot: {
    border: 'none !important',
    margin:'16px',
    height: '56px',
  },
  hasAnnouncement: {
    paddingTop: '52px',
  },
  showpageShade: {
    width: '100vw',
    height: '100%',
    background: 'rgba(0,0,0,0.25)',
    position: 'fixed',
    zIndex: -1,
    top: '0',
    left: '0',
  },
  drawerLeft: {
    top:`${SEARCH_DRAWER_TOP}px`,
    height: `calc(100vh - ${APPBAR.HEIGHT}px - ${SEARCH_DRAWER_TOP}px)`,
    [theme.breakpoints.down('sm')]: {
      top:`${SEARCH_DRAWER_TOP_MOBILE}px`,
    },
  },
  drawerNotOpenStyle: {
    transform:'translateX(-270px)',
  },
  handshakesIconLink: {
    display: 'flex',
    color: '#fff',
    alignItems: 'center',
    fontSize: 14, 
    textDecoration: 'none'
  },
  handshakesIcon: {
    width: 140,
    height: 35,
  },

  drawerFooterFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'MetricWeb',
    fontWeight: FONT.WEIGHT.REGULAR,
  },
  drawerFooterFlexStart: {
    justifyContent: 'flex-start',
  },
  drawerFooterFontSize: {
    fontSize: 12,
  },
  handshakesIconLinkMobile: {
    fontSize: 16,
    textDecoration: 'none'
  },
  drawerFooterMobile: {
    width: '100%',
    backgroundColor: FONT.COLOR.DRAWER.FOOTER,
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} 0`,
    textAlign: 'center'
  },
  drawerFooterLinkRootMobile: {
    '& > span': {
      '& a:not(:first-child)': {
        marginLeft: theme.spacing(3.5),
      },
    },
  },
  drawerFooterCopyrightLabelMobile: {
    marginTop: '10px',
    marginBottom: '10px'
  }
}));
