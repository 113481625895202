import React from 'react';
import { SvgIcon } from '@mui/material';
import { ISvgIconProps } from '../props/common/svgicon.props';

export interface IHandshakesIcon extends ISvgIconProps {
  reverseColor?: boolean,
  viewBox?: string
}

const HandshakesIcon = (props: IHandshakesIcon) => {
  const { className, reverseColor, viewBox } = props;
  const svg = (
    <g id="Primitives-/-Logos-/-Nikkei-FT-HS-(white-bg-wide,-pixel-fitted)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="handshakes-logo-copy" transform="translate(0.000000, 12.000000)" fill={reverseColor ? 'black' : 'white'} fillRule="nonzero">
        <g id="Group" transform="translate(24.036667, 0.000000)">
          <path d="M19.8566667,12.75 L19.44,12.75 C18.8533333,13.4866667 17.8333333,14 16.47,14 C14.2766667,14 13.0466667,12.5233333 13.0466667,10.8966667 C13.0466667,9.36333333 14.05,7.73666667 16.6966667,7.73666667 L19.8,7.73666667 L19.8,7.35666667 C19.8,6.20333333 19.1366667,5.37 17.4733333,5.37 C16.0533333,5.37 15.3366667,5.92 15.26,6.75 L13.6533333,6.75 C13.7866667,4.99 15.3,3.97 17.4366667,3.97 C19.84,3.97 21.3733333,5.12333333 21.3733333,7.26333333 L21.3733333,13.7533333 L19.8566667,13.7533333 L19.8566667,12.75 Z M19.8,9.89333333 L19.8,9.02333333 L16.9433333,9.02333333 C15.3733333,9.02333333 14.6533333,9.70333333 14.6533333,10.84 C14.6533333,11.92 15.4466667,12.6 16.8666667,12.6 C18.4566667,12.6 19.8,11.4066667 19.8,9.89333333 Z" id="Shape" />
          <path d="M30.9333333,13.7533333 C30.7433333,13.7533333 30.59,13.6 30.59,13.41 L30.59,8.04 C30.59,6.28 29.7766667,5.41 28.17,5.41 C26.4666667,5.41 25.3333333,6.90333333 25.3333333,8.59 L25.3333333,13.7533333 L23.7233333,13.7533333 L23.7233333,4.22 L25.2733333,4.22 L25.2733333,5.24 L25.69,5.24 C26.22,4.50333333 27.28,3.97333333 28.5666667,3.97333333 C30.9133333,3.97333333 32.2,5.41 32.2,7.7 L32.2,13.7533333 L30.9333333,13.7533333 Z" id="Path" />
          <path d="M43.3033333,0 L43.3033333,13.41 C43.3033333,13.6 43.15,13.7533333 42.96,13.7533333 L41.7533333,13.7533333 L41.7533333,12.7333333 L41.3366667,12.7333333 C40.8066667,13.3966667 39.88,14 38.3666667,14 C35.7366667,14 34.1866667,11.8066667 34.1866667,8.98666667 C34.1866667,6.11 35.7366667,3.97333333 38.3666667,3.97333333 C39.88,3.97333333 40.75,4.56 41.28,5.24 L41.6966667,5.24 L41.6966667,0.343333333 C41.6966667,0.153333333 41.85,0 42.04,0 L43.3033333,0 Z M41.7166667,8.98666667 C41.7166667,7.11333333 40.8466667,5.37333333 38.7266667,5.37333333 C36.8166667,5.37333333 35.7933333,6.86666667 35.7933333,8.98666667 C35.7933333,11.1066667 36.8166667,12.6 38.7266667,12.6 C40.8466667,12.6 41.7166667,10.86 41.7166667,8.98666667 Z" id="Shape" />
          <path d="M49.5866667,7.96333333 L50.6633333,8.13333333 C52.3266667,8.41666667 53.3866667,9.27 53.3866667,11.01 C53.3866667,12.58 52.0433333,14 49.47,14 C46.7633333,14 45.44,12.8066667 45.2333333,10.9933333 L46.86,10.9933333 C47.01,12.0733333 47.9566667,12.6 49.3966667,12.6 C50.8333333,12.6 51.78,12.09 51.78,11.05 C51.78,10.2166667 51.25,9.76333333 50.04,9.55666667 L47.9033333,9.19666667 C46.54,8.95 45.6133333,8.23333333 45.6133333,6.79333333 C45.6133333,5.20333333 46.8066667,3.97333333 49.3766667,3.97333333 C51.6266667,3.97333333 52.9333333,5.03333333 53.14,6.69666667 L51.5333333,6.69666667 C51.4,5.79 50.4933333,5.37333333 49.3,5.37333333 C48.07,5.37333333 47.2366667,5.82666667 47.2366667,6.64 C47.2366667,7.37666667 47.71,7.66 48.6733333,7.81333333 L49.5866667,7.96333333 Z" id="Path" />
          <path d="M62.5866667,13.7533333 C62.3966667,13.7533333 62.2433333,13.6 62.2433333,13.41 L62.2433333,8.04 C62.2433333,6.31666667 61.43,5.41 59.8233333,5.41 C58.12,5.41 56.9866667,6.90333333 56.9866667,8.59 L56.9866667,13.7533333 L55.38,13.7533333 L55.38,0.343333333 C55.38,0.153333333 55.5333333,0 55.7233333,0 L56.9866667,0 L56.9866667,5.24 L57.4033333,5.24 C57.9133333,4.50333333 58.9533333,3.97333333 60.24,3.97333333 C62.5666667,3.97333333 63.8533333,5.41 63.8533333,7.7 L63.8533333,13.7533333 L62.5866667,13.7533333 Z" id="Path" />
          <path d="M72.5,12.75 L72.0833333,12.75 C71.4966667,13.4866667 70.4766667,14 69.1133333,14 C66.92,14 65.69,12.5233333 65.69,10.8966667 C65.69,9.36333333 66.6933333,7.73666667 69.34,7.73666667 L72.4433333,7.73666667 L72.4433333,7.35666667 C72.4433333,6.20333333 71.78,5.37 70.1166667,5.37 C68.6966667,5.37 67.98,5.92 67.9033333,6.75 L66.2966667,6.75 C66.43,4.99 67.9433333,3.97 70.08,3.97 C72.4833333,3.97 74.0166667,5.12333333 74.0166667,7.26333333 L74.0166667,13.7533333 L72.5033333,13.7533333 L72.5033333,12.75 L72.5,12.75 L72.5,12.75 Z M72.44,9.89333333 L72.44,9.02333333 L69.5833333,9.02333333 C68.0133333,9.02333333 67.2933333,9.70333333 67.2933333,10.84 C67.2933333,11.92 68.0866667,12.6 69.5066667,12.6 C71.1,12.6 72.44,11.4066667 72.44,9.89333333 Z" id="Shape" />
          <path d="M83.53,13.7533333 L80.8066667,8.57 L80.39,8.57 L78.1966667,10.99 L78.1966667,13.7533333 L76.9333333,13.7533333 C76.7433333,13.7533333 76.59,13.6 76.59,13.41 L76.59,0.343333333 C76.59,0.153333333 76.7433333,3.55271368e-16 76.9333333,3.55271368e-16 L78.1966667,3.55271368e-16 L78.1966667,9.00666667 L82.7,4.22 L84.61,4.22 L81.8666667,7.15333333 L85.2533333,13.7566667 L83.53,13.7566667 L83.53,13.7533333 Z" id="Path" />
          <path d="M94.9366667,8.96666667 L87.6733333,8.96666667 C87.73,11.2366667 88.7533333,12.6 90.72,12.6 C92.0633333,12.6 92.8566667,12.0333333 93.14,11.1233333 L94.7666667,11.1233333 C94.4633333,12.75 93.0833333,14 90.7166667,14 C87.7466667,14 86.08,11.8233333 86.08,8.89333333 C86.08,5.96 87.7833333,3.97333333 90.6766667,3.97333333 C93.5133333,3.97333333 94.8766667,6.07333333 94.9333333,8.42 L94.9333333,8.96666667 L94.9366667,8.96666667 Z M87.7666667,7.62333333 L93.2733333,7.62333333 C93.18,6.41333333 92.2533333,5.37333333 90.6433333,5.37333333 C88.92,5.37333333 87.9766667,6.41333333 87.7666667,7.62333333 Z" id="Shape" />
          <path d="M100.613333,7.96333333 L101.69,8.13333333 C103.353333,8.41666667 104.413333,9.27 104.413333,11.01 C104.413333,12.58 103.07,14 100.496667,14 C97.79,14 96.4666667,12.8066667 96.26,10.9933333 L97.8866667,10.9933333 C98.0366667,12.0733333 98.9833333,12.6 100.423333,12.6 C101.86,12.6 102.806667,12.09 102.806667,11.05 C102.806667,10.2166667 102.276667,9.76333333 101.066667,9.55666667 L98.93,9.19666667 C97.5666667,8.95 96.64,8.23333333 96.64,6.79333333 C96.64,5.20333333 97.8333333,3.97333333 100.403333,3.97333333 C102.653333,3.97333333 103.96,5.03333333 104.166667,6.69666667 L102.56,6.69666667 C102.426667,5.79 101.52,5.37333333 100.326667,5.37333333 C99.0966667,5.37333333 98.2633333,5.82666667 98.2633333,6.64 C98.2633333,7.37666667 98.7366667,7.66 99.7,7.81333333 L100.613333,7.96333333 Z" id="Path" />
          <polygon id="Path" points="10.8933333 0.293333333 10.8933333 13.7233333 9.2 13.7233333 9.2 7.59 1.69333333 7.59 1.69333333 13.7233333 -3.78956126e-15 13.7233333 -3.78956126e-15 0.293333333 1.69333333 0.293333333 1.69333333 6.01 9.2 6.01 9.2 0.293333333" />
        </g>
        <g id="Group">
          <circle id="Oval" cx="1.98" cy="1.98" r="1.98" />
          <circle id="Oval" cx="1.98" cy="7" r="1.98" />
          <circle id="Oval" cx="1.98" cy="12.02" r="1.98" />
          <circle id="Oval" cx="7" cy="7" r="1.98" />
          <circle id="Oval" cx="12.02" cy="1.98" r="1.98" />
          <circle id="Oval" cx="12.02" cy="7" r="1.98" />
          <circle id="Oval" cx="12.02" cy="12.02" r="1.98" />
          <path d="M3.38333333,5.6 C4.16666667,6.38333333 4.81666667,6.38333333 5.6,5.6 L5.6,8.4 C4.81666667,7.61666667 4.16666667,7.61666667 3.38333333,8.4 L3.38333333,5.6 Z" id="Path" />
        </g>
      </g>
    </g>
  );

  return (
    <SvgIcon viewBox={viewBox} className={className}>
      {svg}
    </SvgIcon>
  );
};

HandshakesIcon.defaultProps = {} as Partial<IHandshakesIcon>;

export default HandshakesIcon;