import { CONFIGURATIONS } from '@constants/actions';
import { DRAWER_ALERT_CONTROL, OWNERSHIP_TABLE, SEER_OWNERSHIP_TABLE } from '@constants/common';
import createAction from './actionCreator';

export const updateIsPrinting = (isPrinting: boolean) => {
  return (dispatch) => {
    dispatch(createAction(CONFIGURATIONS.PRINT, { isPrinting }));
  };
};

export const updateIsDownloading = (isDownloading: boolean) => {
  return (dispatch) => {
    dispatch(createAction(CONFIGURATIONS.DOWNLOAD, { isDownloading }));
  };
};

export const updateDrawerAlertControl = (value: DRAWER_ALERT_CONTROL) => {
  return (dispatch) => {
    dispatch(createAction(CONFIGURATIONS.DRAWER_ALERT, { value }));
  };
};

export const updateDrawerSaveFieldShow = (isShow: boolean) => {
  return (dispatch) => {
    dispatch(createAction(CONFIGURATIONS.DRAWER_SAVED_FIELD, { isShow }));
  };
};

export const updateOwnershipTableTypeAction = (value: OWNERSHIP_TABLE) => {
  return (dispatch) => {
    dispatch(createAction(CONFIGURATIONS.OWNERSHIP_TABLE_TYPE, { value }));
  };
};

export const updateSeerOwnershipTableTypeAction = (value: SEER_OWNERSHIP_TABLE) => {
  return (dispatch) => {
    dispatch(createAction(CONFIGURATIONS.SEER_OWNERSHIP_TABLE_TYPE, { value }));
  };
};


export const updateIsBackToSearch = (isShow: boolean) => {
  return (dispatch) => {
    dispatch(createAction(CONFIGURATIONS.PREVIOUS_IS_SEARCH, { isShow }));
  };
};
