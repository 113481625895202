import React from 'react';
import classnames from 'classnames';
import AnnouncementInfo from '@react/components/announcement/announcementInfo.component';
import { useStyles } from './styles';


export interface AnnouncementContainerProps {
  show: boolean;
  storedAnnouncement;
  fixed;
  onCancel;
}
 
const AnnouncementContainer: React.FC<AnnouncementContainerProps> = ({
  show,
  storedAnnouncement,
  fixed,
  onCancel,
}) => {
  const classes = useStyles({
    hasAnnouncement: show,
  });
  if (!show) {
    return null;
  }
  return (
    <>
      <AnnouncementInfo
        announcement={storedAnnouncement?.announcement}
        className={classnames(classes.announcementRoot, {
          [classes.announcementRootFixed]: fixed,
        })}
        onCancel={onCancel}
      />
      {
        fixed && <div className={classes.announcementSpace} />
      }
    </>
  );
};
 
export default AnnouncementContainer;