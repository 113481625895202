import React from 'react';
import { IBookmarkSnackBar } from '@models/bookmark';
import { connect } from 'react-redux';
import { setBookmarkSnackBar } from '@redux/actions/bookmark.actions';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import MessageSnackBar from '../message/messageSnackBar.component';


type IBookmarkSnackBarProps = IStateProps & IDispatchProps;

const BookmarkSnackBar = (props: IBookmarkSnackBarProps) => {
  const {
    snackBar,
    setBookmarkSnackBarAction,
  } = props;

  return (
    <>
      <MessageSnackBar
        customKey={`${moment().unix()}`}
        open={snackBar.open}
        autoHideTime={3000}
        onClose={() => {
          setBookmarkSnackBarAction({ 
            ...snackBar,
            open: false, 
          });
        }}
        message={snackBar.message}
        mini={snackBar.mini}
        horizontal="center"
        error={snackBar.error}
        action={snackBar.action}
        title={snackBar.title}
      />
    </>
  );
};

interface IStateProps {
  snackBar: IBookmarkSnackBar;
}

interface IDispatchProps {
  setBookmarkSnackBarAction;
}

const mapStateToProps = (state) => ({
  snackBar: state.getIn(['Bookmark', 'snackBar']),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setBookmarkSnackBarAction: setBookmarkSnackBar,
    },
    dispatch,
  ),
});

export default connect<IStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(BookmarkSnackBar);



