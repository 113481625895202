import React, { useEffect, useState, ReactNode } from 'react';
import { Box, Slide, Snackbar } from '@mui/material';
import classnames from 'classnames';
import { TransitionProps } from '@mui/material/transitions';
import TickIcon from '../icons/tickIcon.component';
import MessageBase from './messageBase.component';
import useStyles from './styles';
import ExclaimationMarkSquareIcon from '../icons/exclaimationMarkSquareIcon.component';
import MessageWarning from './messageWarning.component';

type Direction = 'up' | 'left' | 'right' | 'down';

interface ITransitionProps extends TransitionProps {
  direction?: Direction;
}

interface IMessageSnackBarProps {
  vertical?: 'top' | 'bottom',
  horizontal?: 'left' | 'center' | 'right',
  autoHideTime?: number,
  message: ReactNode,
  title?: ReactNode,
  error?: boolean,
  open: boolean,
  onClose?: () => void,
  action?: ReactNode,
  mini?: boolean, 
  className?: string,
  direction?: Direction,
  customKey?: any,
}

const MessageSnackBar = (props: IMessageSnackBarProps) => {
  const {
    vertical = 'bottom',
    horizontal = 'left',
    message,
    title = null,
    autoHideTime = null,
    error = false,
    open,
    onClose,
    action = null,
    mini,
    className,
    direction,
    customKey,
  } = props;

  const [localOpen, setLocalOpen] = useState(false);

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  const classes = useStyles();

  const renderLeadingIcon = (
    <Box className={classnames({
      [classes.iconWarningRoot]: error,
      [classes.iconInfoRoot]: !error,
    })}
    >
      {error ? <ExclaimationMarkSquareIcon color="#FFFFFF" /> : <TickIcon color="#FFFFFF" />}
    </Box>
  );

  const handleClose = () => {
    setLocalOpen(false);
  };

  const messageElement = (
    error ?
      <MessageWarning
        className={classes.warningRoot}
        title={title}
        message={message}
        onCancel={onClose || handleClose}
        mini={mini}
      /> :
      <MessageBase
        className={classnames(classes.snackBarMessageRoot, classes.infoRoot)}
        leadingIcon={renderLeadingIcon}
        title={title}
        message={message}
      />
  );

  
  function SlideTransition(props: ITransitionProps) {
    return <Slide {...props} direction={direction || 'up'} />;
  }
  return (
    <Snackbar
      style={{
        display: (localOpen) ? 'block': 'none'
      }}
      key={customKey}
      open={localOpen}
      className={classnames({
        [classes.bookmarkSnackBar]: !mini,
        [classes.commonSnackBar]: mini,
        [classes.bookmarkErrSnackBar]: !mini && error,
      }, className)}
      autoHideDuration={autoHideTime}
      onClose={onClose || handleClose}
      anchorOrigin={{ vertical, horizontal }}
      TransitionComponent={SlideTransition}
      message={messageElement}
      action={action}
    />
  );
};



export default MessageSnackBar;