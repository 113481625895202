import React from 'react';
import { Typography, Link, Grid, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import { EXTERNAL_LINK } from '@constants/common';
import theme from '@react/theme/theme';
import classNames from 'classnames';
import { SITE_URLS } from '@constants/routes';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { loggedInSelector } from '@redux/selectors/account.selector';
import { useStyles } from './style';
import messages from './messages';
import NikkeiIcon from '../icons/nikkeiIcon.component';
import AtlasDataTrackable from '../atlas/atlas.component';
import HandshakesIcon from '../icons/handshakesIcon.component';

interface SearchDrawerFooterProps extends ISearchDrawerFooterStateProps {
}


const SearchDrawerFooter = (props: SearchDrawerFooterProps) => {
  const { isLogin } = props;
  const classes = useStyles();
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const history = useHistory<any>();
  const renderNikkeiIcon = () => (
    <a 
      href={EXTERNAL_LINK.NIKKEI} 
      target="_blank" 
      rel="noopener noreferrer"
      className={classes.nikkeIconLink}
    >
      <NikkeiIcon className={classes.nikkeiIcon} viewBox="0 0 70 35" />
    </a>
  );

  const renderHandshakes = () => (
    <a
      href={EXTERNAL_LINK.HANDSHAKES}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(classes.handshakesIconLink, isMobile && classes.handshakesIconLinkMobile)}
    >
      <HandshakesIcon
        className={classNames(classes.handshakesIcon)}
        viewBox="0 0 138 38"
      />
    </a>
  );

  const linkTargetProps = {
    target: '_blank',
    rel: 'noreferrer',
  };

  const links = [
    {
      href: EXTERNAL_LINK.SCOUT_ASIA.TERM_CONDITION,
      label: intl.formatMessage(messages.termCondition),
    },
    {
      href: EXTERNAL_LINK.SCOUT_ASIA.PRIVACY,
      label: intl.formatMessage(messages.privacyPolicy),
    },
    {
      href: EXTERNAL_LINK.SCOUT_ASIA.COOKIES,
      label: intl.formatMessage(messages.cookiesPolicy),
    },
    {
      href: EXTERNAL_LINK.INFORMATION_TRANSMISSION,
      label: intl.formatMessage(messages.informationTransmission),
    },
  ];

  if (isLogin && !(history.location.pathname === SITE_URLS.ACCOUNT_REGISTRATION)) {
    links.push({
      href: SITE_URLS.CMS_FAQ,
      label: intl.formatMessage(messages.faq),
    });
  } 

  const renderLinks = (
    <div
      className={classNames(
        classes.drawerFooterLinkRoot,
        isMobile && classes.drawerFooterLinkRootMobile
      )}
    >
      <Typography variant="caption">
        {links.map((link) => (
          <Link
            {...AtlasDataTrackable({
              targetAttribute: link.label.toLowerCase(),
            })}
            key={`link-${link.label}`}
            href={link.href}
            {...linkTargetProps}
          >
            {link.label}
          </Link>
        ))}
      </Typography>
    </div>
  );

  return (
    <Grid
      className={classNames(
        !isMobile ? classes.drawerFooterRoot : classes.drawerFooterMobile
      )}
      container
    >
      <Grid
        className={classNames(classes.drawerFooterFlex)}
        item
        sm={5}
        xs={12}
      >
        {renderNikkeiIcon()}
        {renderHandshakes()}
      </Grid>
      <Grid
        className={classNames(
          classes.drawerFooterFlex,
          !isMobile && classes.drawerFooterFlexStart
        )}
        sm={5}
        xs={12}
        item
      >
        {renderLinks}
      </Grid>
      <Grid
        className={classNames(
          classes.drawerFooterFlex,
          classes.drawerFooterFontSize,
          isMobile && classes.drawerFooterCopyrightLabelMobile
        )}
        item
        sm={2}
        xs={12}
      >
        {intl.formatMessage(messages.copyright, {
          year: new Date().getFullYear(),
        })}
      </Grid>
    </Grid>
  );
};

interface ISearchDrawerFooterStateProps {
  isLogin: Boolean;

}

const mapStateToProps = (state, props) => ({
  isLogin: loggedInSelector(state),
});



export default  connect<ISearchDrawerFooterStateProps, null>(mapStateToProps, null)(SearchDrawerFooter);
