import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '52px',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px 10px',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
          flex: 1,
          padding: `0px ${theme.spacing(2)}`,
        },
      },
    },
    backgroundInfo: {
      backgroundColor: '#d4ecea',
    },
    backgroundWarning: {
      backgroundColor: '#fad9ce',
    },
    buttonCancelRoot: {
      fontSize: '12px',
      width: '24px',
      height: '24px',
    },
    textMessage: {
      flex: 1,
      marginLeft: theme.spacing(2),
      lineHeight: '20px',
    },
    textMessageInfo: {
      display: 'flex',
      '& > p': {
        flex: 1,
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: theme.spacing(0.5),
      },
      '& a': {
        fontWeight: 500,
      },
    },
  }),
);

export default useStyles;