import { ROUTE_PATHS } from '@constants/routes';

export interface IRouteUiProps {
  responsive?: boolean;
  noOverflow?: boolean;
  announcementIsFixed?: boolean;
  fullBodyWidth?: boolean;
}

export const routesUiProps = {
  '/': {
    responsive: false,
    announcementIsFixed: true,
    paddingX: 0,
  },
  [ROUTE_PATHS.ACCOUNT_ACTIVATION]: {
    responsive: false,
    announcementIsFixed: true,
    paddingX: 0,
  },
  [ROUTE_PATHS.SEARCH_ARTICLE]: {
    responsive: false,
    noOverflow: true,
    announcementIsFixed: true,
  },
  [ROUTE_PATHS.SEARCH_COMPANY]: {
    responsive: false,
    noOverflow: true,
    announcementIsFixed: true,
  },
  [ROUTE_PATHS.CONNECTION_DETAIL]: {
    noOverflow: true,
    fullBodyWidth: true,
  },
  [ROUTE_PATHS.SCOUT_AI_TRAINING]: {
    fullBodyWidth: true,
  },
  [ROUTE_PATHS.SCOUT_AI_RESULT]: {
    fullBodyWidth: true,
  },
  [ROUTE_PATHS.SCOUT_AI_VISUALISATION]: {
    fullBodyWidth: true,
  },
  [ROUTE_PATHS.SCOUT_AI_CONTINUE_TRAINING]: {
    fullBodyWidth: true,
  },
  [ROUTE_PATHS.RADAR_LANDING]: {
    announcementIsFixed: true,
    fullBodyWidth: true,
  },
  [ROUTE_PATHS.RADAR_DETAIL]: {
    fullBodyWidth: true,
    announcementIsFixed: true,
  },
  [ROUTE_PATHS.RADAR_SETTINGS]: {
    fullBodyWidth: true,
    announcementIsFixed: true,
  },
  [ROUTE_PATHS.LIBRARY_OVERVIEW]: {
    announcementIsFixed: true,
  },
  [ROUTE_PATHS.QUICK_SEARCH]: {
    fullBodyWidth: true,
    announcementIsFixed: true,
  },
  [ROUTE_PATHS.SAVED]: {
    fullBodyWidth: true,
    announcementIsFixed: true,
  },
};
