import { STANDARD_LAYOUT_DEFAULT_UI_PROPS } from '@constants/component';
import React from 'react';
import { Switch } from 'react-router';
import AuthRoute from '@react/components/route/authRoute.component';
import AnonymouseRoute from '@react/components/route/anonymousRoute.component';
import { Helmet } from 'react-helmet';
import { SITE } from '@constants/common';
import SearchDrawerFooter from '@react/components/drawer/searchDrawerFooter.component';
import NonResponsiveContent from './nonResponsive.content';
import ResponsiveContent from './responsive.content';
import AnnouncementContainer from './announcement.container';

export interface StandardLayoutContentProps {
  uiRoutes;
  uiControlProps;
  showAnnouncement;
  storedAnnouncement;
  handleOnAnnouncementCancel;
  subcriptionFeaturePermissionModel;
}
 
const StandardLayoutContent: React.FC<StandardLayoutContentProps> = React.memo(({
  uiRoutes,
  uiControlProps,
  showAnnouncement,
  storedAnnouncement,
  handleOnAnnouncementCancel,
  subcriptionFeaturePermissionModel,
}) => {
  return (
    <Switch>
      {uiRoutes.map((route) => {
        const routeCompProps = {
          path: route.path,
          exact: !!route.exact,
          render: (routeProps) => {
            const responsive = route.uiProps.responsive !== undefined ? route.uiProps.responsive : STANDARD_LAYOUT_DEFAULT_UI_PROPS.responsive;
            const announcementIsFixed = route.uiProps.announcementIsFixed !== undefined ? route.uiProps.announcementIsFixed : STANDARD_LAYOUT_DEFAULT_UI_PROPS.announcementIsFixed;
            return (
              <>
                <Helmet
                  titleTemplate={`%s | ${SITE.TITLE}`}
                  title={route.documentTitle()}
                />
                <AnnouncementContainer
                  show={showAnnouncement}
                  storedAnnouncement={storedAnnouncement}
                  fixed={announcementIsFixed}
                  onCancel={handleOnAnnouncementCancel}
                />
                {responsive ?
                  <ResponsiveContent
                    route={route}
                    routeProps={routeProps}
                    uiControlProps={uiControlProps}
                    showAnnouncement={showAnnouncement}
                    subcriptionFeaturePermissionModel={subcriptionFeaturePermissionModel}
                  />
                  :
                  <NonResponsiveContent
                    route={route}
                    uiControlProps={uiControlProps}
                    showAnnouncement={showAnnouncement}
                    subcriptionFeaturePermissionModel={subcriptionFeaturePermissionModel}
                  />}
                <SearchDrawerFooter />
              </>
            );
          },
        };
        if (route.authValid) {
          return (
            <AuthRoute
              key={route.path}
              {...routeCompProps}
            />
          );  
        }
        return (
          <AnonymouseRoute
            key={route.path}
            {...routeCompProps}
          />
        );
      })}
    </Switch>
  );
});
 
export default StandardLayoutContent;