import React from 'react';
import { STANDARD_LAYOUT_DEFAULT_UI_PROPS } from '@constants/component';
import { Box } from '@mui/material';
import { FEATURE_CODE } from '@constants/common';
import Error401Page from '@react/pages/error/error401.page';
import classnames from 'classnames';
import { useStyles } from './styles';

export interface NonResponsiveContentProps {
  route;
  uiControlProps;
  showAnnouncement;
  subcriptionFeaturePermissionModel;
}
 
const NonResponsiveContent: React.FC<NonResponsiveContentProps> = ({
  route,
  uiControlProps,
  showAnnouncement,
  subcriptionFeaturePermissionModel,
}) => {
  const Component = route.component;
  const noOverflow = route.uiProps.noOverflow !== undefined ? route.uiProps.noOverflow : STANDARD_LAYOUT_DEFAULT_UI_PROPS.noOverflow;
  const paddingX = route.uiProps.paddingX !== undefined ? route.uiProps.paddingX : STANDARD_LAYOUT_DEFAULT_UI_PROPS.paddingX;
  const availableForStartUser = route.availableForStartUser;
  const isStarterUser = subcriptionFeaturePermissionModel.includes(FEATURE_CODE.HIDE_ARTICLE_TOOLBAR_RIGHTTABS);
  const availableForNonActivatedUser = route.availableForNonActivatedUser;
  const isActiveUser = true;
  const announcementIsFixed = route.uiProps.announcementIsFixed !== undefined ? route.uiProps.announcementIsFixed : STANDARD_LAYOUT_DEFAULT_UI_PROPS.announcementIsFixed;

  const classes = useStyles({
    hasAnnouncement: showAnnouncement,
    fixedAnnouncement: announcementIsFixed,
  });
  return (
    <Box
      className={classnames(
        classes.contentRootBase,
        classes.contentFullWidthRoot,
        classes.contentRootNonResponsive, {
          [classes.contentRootFixed]: noOverflow,
        })}
    >
      <Box
        className={classnames({
          [classes.contentBodyFixed]: noOverflow,
        })}
        paddingX={paddingX}
      >
        {(!availableForStartUser && isStarterUser) || (!availableForNonActivatedUser && !isActiveUser) ? <Error401Page /> : <Component {...uiControlProps} />}
      </Box>
    </Box>
  );
};
 
export default NonResponsiveContent;